<template>
  <v-container>
    <v-row class="justify-center align-self-start">
      <v-col class="col-md-8 col-sm-10">
        <v-card class="py-9 px-12">
          <v-card-title
            class="primary--text headline"
          >
            Deprecations &amp; Migrations
          </v-card-title>

          <v-card-text>
            <h3
              id="iadops-v1"
              class="mb-3 subtitle-1 primary--text font-weight-bold"
            >
              iAdOps v1
            </h3>

            <div>
              <h4>v1 Deprecation &amp; v2 Migration</h4>
              <h5 class="caption mb-1">
                Oct 14, 2020 at 11:59 PM ET
              </h5>

              <p>
                Users visiting iadops.com will load iAdOps v2. The legacy platform, iAdOps v1, will remain accessible to users via legacy.iadops.com.
              </p>
            </div>

            <div>
              <h4>Sunset</h4>
              <h5 class="caption mb-1">
                Oct 31, 2020 at 11:59 PM ET
              </h5>

              <p>
                After this time, iAdOps v1 will become unstable due to third-party API deprecations. The iAdOps v1 product will be sunset and user access to legacy.iadops.com will be revoked.
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DeprecationNotes',
}
</script>
